import Vue from 'vue'

export default {
    SET_FILE_LIST(state, { data, key, page }) {
        data.page = page
        
        if(state.files[key]) {
            const existFileList = state.files[key].results
            state.files[key] = data
            state.files[key].results.unshift(...existFileList)
        } else {
            Vue.set(state.files, key, data)
        }
    },
    ADD_FILE(state, { data, key }) {
        if(!state.files[key]) {
            Vue.set(state.files, key, {
                results: []
            })
        }
        
        if(Array.isArray(data))
            state.files[key].results.unshift(...data)
        else
            state.files[key].results.unshift(data)
    },
    REMOVE_FILES(state, {removedFiles, key}) {
        for(const removedFile of removedFiles) {
            const fileIndex = state.files[key].results.findIndex(file => file.id === removedFile.id)
            state.files[key].results.splice(fileIndex, 1)
        }
    },
    SET_FILE_VIEW_TYPE(state, viewType) {
        localStorage.setItem('filesViewType', viewType)
    },
    RENAME_FILE(state, { fileId, newFileName, newFileDesc, key }) {
        const renamingFile = state.files[key].results.find(file => file.id === fileId)
        renamingFile.name = newFileName
        renamingFile.description = newFileDesc
    },
    CLEAR_ALL(state, sourceId) {
        if(state.files[sourceId]) {
            state.files[sourceId].next = true
            state.files[sourceId].page = 0
            state.files[sourceId].results.splice(0)
        }
    }
}
